<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-13 15:39:33
 * @LastEditTime: 2022-11-11 14:41:33
 * @LastEditors: wangjuan
 * @FilePath: \sns_web\src\views\layout\work\okr\alignTheView\TargetNotAligned.vue
-->
<template>
  <div class="tree-container"
  v-loading="loading"
  element-loading-text="Loading...">
    <el-scrollbar ref="scrollRef">
      <div class="department-list">
        <div class="department" v-for="(deptItem, index) in deptName" :key="index">
            <h3 class="title">{{deptItem.name}}</h3>
            <div :style="deptItem.childList.length < 1 ? 'text-algin: center;' : 'display: flex; justify-content: space-between;'">
              <div v-for="(childItem, index) in deptItem.childList" :key="index">
                <h5 class="dept-item-title">{{childItem}}</h5>
                <el-scrollbar>
                  <div class="three-card-list">
                    <template v-for="(alignedItem, aIndex) in alignedList" :key="aIndex" >
                      <section class="three-card no-alignment" v-if="alignedItem.level === deptItem.level && alignedItem.ork_type === childItem" @click="dialog(alignedItem)">
                        <div class="three-card-head">
                          <p class="tag">{{alignedItem.ork_type}}</p>
                          <el-progress type="circle" :percentage="Number(alignedItem.schedule)" show-text :width="Number(16)" :stroke-width="Number(3)" stroke-linecap="butt" color="#7972F0"/>
                        </div>
                        <div class="three-card-content">
                          <h3>{{alignedItem.objective_name}}</h3>
                          <p class="tag">
                            <span class="dept">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="16" height="16" rx="8" fill="#7972F0"/>
                                <path d="M6.0013 3.33333C6.0013 2.59695 6.59826 2 7.33464 2H8.66797C9.40435 2 10.0013 2.59695 10.0013 3.33333V4.66667C10.0013 5.40305 9.40435 6 8.66797 6V7.33333H10.668C11.7725 7.33333 12.668 8.22876 12.668 9.33333V10C13.0362 10 13.3346 10.2985 13.3346 10.6667V12C13.3346 12.3682 13.0362 12.6667 12.668 12.6667H11.3346C10.9664 12.6667 10.668 12.3682 10.668 12V10.6667C10.668 10.2985 10.9664 10 11.3346 10V9.33333C11.3346 8.96514 11.0362 8.66667 10.668 8.66667H8.66797V10C9.03616 10 9.33464 10.2985 9.33464 10.6667V12C9.33464 12.3682 9.03616 12.6667 8.66797 12.6667H7.33464C6.96645 12.6667 6.66797 12.3682 6.66797 12V10.6667C6.66797 10.2985 6.96645 10 7.33464 10V8.66667H5.33464C4.96645 8.66667 4.66797 8.96514 4.66797 9.33333V10C5.03616 10 5.33464 10.2985 5.33464 10.6667V12C5.33464 12.3682 5.03616 12.6667 4.66797 12.6667H3.33464C2.96645 12.6667 2.66797 12.3682 2.66797 12V10.6667C2.66797 10.2985 2.96645 10 3.33464 10V9.33333C3.33464 8.22876 4.23007 7.33333 5.33464 7.33333H7.33464V6C6.59826 6 6.0013 5.40305 6.0013 4.66667V3.33333Z" fill="white"/>
                              </svg>
                              <span>{{alignedItem.dept_name}}</span>
                            </span>
                            <span class="cycle">{{alignedItem.cycle}}</span>
                          </p>
                        </div>
                      </section>
                    </template>
                  </div>
                </el-scrollbar>
              </div>
            </div>
        </div>

      </div>
    </el-scrollbar>
  </div>
  <TargetOkrDialog ref="targetOkrDialogRef" :iconStatus="false"></TargetOkrDialog>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue'
import { getAlignList } from '@/apis/okr.js'
import eventBus from '@/utils/eventBus.js'
import TargetOkrDialog from '@/views/layout/work/okr/alignTheView/component/TargetOkrDialog'
export default {
  name: 'TargetNotAligned',
  components: { TargetOkrDialog },
  props: {
    dateList: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const state = reactive({
      scrollRef: null,
      deptName: [
        {
          level: 1,
          name: '麦风科技',
          childList: ['公司目标']
        },
        {
          level: 2,
          name: '一级部门',
          childList: ['部门目标', '个人目标']
        },
        {
          level: 3,
          name: '二级部门',
          childList: ['部门目标', '个人目标']
        },
        {
          level: 4,
          name: '三级部门',
          childList: ['部门目标', '个人目标']
        }
      ],
      dialogTableVisible: false,
      loading: false,
      alignedList: [],
      dateList: computed(() => props.dateList),
      targetOkrDialogRef: null
    })

    onMounted(() => {
      getAlignLists()
    })

    // 视图展示
    const getAlignLists = async () => {
      state.loading = true
      const params = { align_type: 2, cycle_id: props.dateList, type: 1 }
      const { code, data } = await getAlignList(params)
      if (code === 0) {
        state.alignedList = data
      }
      state.loading = false
    }

    // 开启弹出
    const dialog = async (item) => {
      state.loading = true
      state.loading = await state.targetOkrDialogRef.dialog(item)
    }

    // 回到顶部
    eventBus.$on('setScrollTop', () => {
      state.scrollRef.setScrollTop(0)
    })

    watch(() => state.dateList, (value) => {
      getAlignLists()
    })

    return {
      ...toRefs(state),
      dialog
    }
  }
}
</script>

<style lang="less" scoped>
.tree-container {
  display: flex;
  justify-content: center;
  position: fixed;
  margin: auto;
  max-width: 1700px;
  height: 800px;
  left: 0;
  right: 0;
  top: 121px;

  .department-list {
    display: flex;
    justify-content: space-between;
    .title {
      padding-bottom: 15px;
      color: #333;
      font-size: 14px;
      text-align: center;
    }

    .department {
      margin-right: 30px;

      .three-card-list {
        width: 230px;
        height: calc(100vh - 220px);

        .three-card {
          margin-bottom: 15px;
        }
        .three-card-head .tag {
          width: 60px;
        }
      }
    }
  }
  :deep(.el-scrollbar) {
    .el-scrollbar__bar.is-horizontal>div {
      height: 10px;
    }
  }
}
.dept-item-title {
  text-align: center;
  padding-bottom: 10px;
}
</style>
